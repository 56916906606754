import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const getAdmins = createAsyncThunk("admins/getAdmins", async () => {
  let data;
  try {
    const response = await axios.get("adminslist");
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
  } catch (err) {
    return { message: err?.message ? err?.message : "Something went wrong!" };
  }
});

export const getOneAdmin = createAsyncThunk("/me", async () => {
  let data;
  try {
    const response = await axios.get(`/api/me`);
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
  } catch (err) {
    return { message: err?.message ? err?.message : "Something went wrong!" };
  }
});

export const postAdmin = createAsyncThunk(
  "admins/postAdmin",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post("adminslist", body);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getAdmins());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Admin has been created successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const putAdmin = createAsyncThunk(
  "admins/putAdmin ",
  async (body, thunkAPI) => {
    const { id, username, email, firstname, lastName, password, photo } = body;
    let data;
    try {
      const response = await axios.put(`api/admin/update/${id}`, {
        username,
        email,
        firstname,
        lastName,
        password,
        photo,
      });
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getOneAdmin());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Admin has been updated successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  "admins/deleteAdmin",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`adminslist/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getAdmins());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Admin has been deleted successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    adminsList: [],
    getOneAdminList: [],
    //getAdmins
    getAdminsStatus: "idle",
    getAdminsError: null,
    //getOneAdmin
    getOneAdminStatus: "idle",
    getOneAdminError: null,
    //postAdmin
    postAdminStatus: "idle",
    postAdminError: null,
    //putAdmin
    putAdminStatus: "idle",
    putAdminError: null,
    //deleteAdmin
    deleteAdminStatus: "idle",
    deleteAdminError: null,
  },
  extraReducers: {
    // getAdmins
    [getAdmins.pending]: (state) => {
      state.getAdminsStatus = "loading";
    },
    [getAdmins.fulfilled]: (state, action) => {
      state.getAdminsStatus = "success";
      state.adminsList = action.payload;
    },
    [getAdmins.rejected]: (state, action) => {
      state.getAdminsStatus = "failed";
      state.getAdminsError = action.error.message;
    },
    //getOneAdmin
    [getOneAdmin.pending]: (state) => {
      state.getOneAdminStatus = "loading";
    },
    [getOneAdmin.fulfilled]: (state, action) => {
      state.getOneAdminStatus = "success";
      state.getOneAdminList = action.payload;
    },
    [getOneAdmin.rejected]: (state, action) => {
      state.getOneAdminStatus = "failed";
      state.getOneAdminError = action.error.message;
    },
    //postAdmin
    [postAdmin.pending]: (state) => {
      state.postAdminStatus = "loading";
    },
    [postAdmin.fulfilled]: (state) => {
      state.postAdminStatus = "success";
    },
    [postAdmin.rejected]: (state, action) => {
      state.postAdminStatus = "failed";
      state.postAdminError = action.error.message;
    },
    //putAdmin
    [putAdmin.pending]: (state) => {
      state.putAdminStatus = "loading";
    },
    [putAdmin.fulfilled]: (state, action) => {
      state.putAdminStatus = "success";
      state.getOneAdminList = action.payload;
    },
    [putAdmin.rejected]: (state, action) => {
      state.putAdminStatus = "failed";
      state.putAdminError = action.error.message;
    },
    //deleteAdmin
    [deleteAdmin.pending]: (state) => {
      state.deleteAdminStatus = "loading";
    },
    [deleteAdmin.fulfilled]: (state, action) => {
      state.deleteAdminStatus = "success";
      state.getOneAdminList = action.payload;
    },
    [deleteAdmin.rejected]: (state, action) => {
      state.deleteAdminStatus = "failed";
      state.deleteAdminError = action.error.message;
    },
  },
});
export const adminActions = adminSlice.actions;
export const reducer = adminSlice.reducer;
export default adminSlice;
