import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { serialize } from "object-to-formdata";
import axios from '../../utils/axios'


const initialState = {
  images: {
    data: [],
    status: "idle",
    error: null,
  },
};

export const fetchImages = createAsyncThunk("imagesManager/fetchTypes", async () => {
  let data;

  try {
    const response = await axios.get("/api/dashboard/images");

    data = await response.data.payload;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const updateImage = createAsyncThunk(
  "imagesManager/updateImage",
  async (param, { dispatch }) => {
    let data;
    const imageId = param.imageId;
    delete param.imageId;

    try {
      const response = await axios.post(
        `/api/dashboard/images/${imageId}`,
        serialize({ ...param }, { indices: true })
      );

      data = await response.data.payload;
      if (response.status === 200) {
        dispatch(fetchImages());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "attributeSet",
  initialState,
  reducers: {
    actions: (state, action) => {},
  },
  extraReducers: {
    [fetchImages.pending]: (state) => {
      state.images.status = "loading";
    },
    [fetchImages.fulfilled]: (state, action) => {
      state.images.status = "succeeded";
      state.images.data = action.payload;
    },
    [fetchImages.rejected]: (state, action) => {
      state.images.status = "failed";
      state.images.error = action.payload;
    },
    [updateImage.pending]: (state) => {
      state.images.status = "loading";
    },
    [updateImage.fulfilled]: (state, action) => {
      state.images.status = "succeeded";
    },
    [updateImage.rejected]: (state, action) => {
      state.images.status = "failed";
      state.images.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const { actions } = slice.actions;

export default slice;
