import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const getProgress = createAsyncThunk(
  "progress/getProgress",
  async (page) => {
    let data;
    if (page) {
      try {
        const response = await axios.get(
          `api/progress/all?perPage=24&page=${page}`
        );
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
      } catch (err) {
        return {
          message: err?.message ? err?.message : "Something went wrong!",
        };
      }
    } 
    else {
      try {
        const response = await axios.get(`api/progress/all`);
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
      } catch (err) {
        return {
          message: err?.message ? err?.message : "Something went wrong!",
        };
      }
    }
  }
);

export const getOneProgress = createAsyncThunk(
  "progress/getOneProgress",
  async (id) => {
    let data;
    try {
      const response = await axios.get(`api/progress/get/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const postProgress = createAsyncThunk(
  "progress/postProgress",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post("api/progress/create", body);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getProgress());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Progress has been created successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const putProgress = createAsyncThunk(
  "progress/putProgress ",
  async (body, thunkAPI) => {
    const { id, user_id, game_id, status, score } = body;
    let data;
    try {
      const response = await axios.put(`api/progress/update/${id}`, {
        user_id,
        game_id,
        status,
        score,
      });

      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getProgress());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Progress has been updated successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const deleteProgress = createAsyncThunk(
  "progress/deleteProgress",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`api/progress/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getProgress());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Progress has been deleted successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

const progressSlice = createSlice({
  name: "progress",
  initialState: {
    progressList: [],
    getOneProgressList: [],
    //getProgress
    getProgressStatus: "idle",
    getProgressError: null,
    //getOneProgress
    getOneProgressStatus: "idle",
    getOneProgressError: null,
    //postProgress
    postProgressStatus: "idle",
    postProgressError: null,
    //putProgress
    putProgressStatus: "idle",
    putProgressError: null,
    //deleteProgress
    deleteProgressStatus: "idle",
    deleteProgressError: null,
  },
  extraReducers: {
    // getGames
    [getProgress.pending]: (state) => {
      state.getProgressStatus = "loading";
    },
    [getProgress.fulfilled]: (state, action) => {
      state.getProgressStatus = "success";
      state.progressList = action.payload;
    },
    [getProgress.rejected]: (state, action) => {
      state.getProgressStatus = "failed";
      state.getProgressError = action.error.message;
    },
    //getOneProgress
    [getOneProgress.pending]: (state) => {
      state.getOneProgressStatus = "loading";
    },
    [getOneProgress.fulfilled]: (state, action) => {
      state.getOneProgressStatus = "success";
      state.getOneProgressList = action.payload;
    },
    [getOneProgress.rejected]: (state, action) => {
      state.getOneProgressStatus = "failed";
      state.getOneProgressError = action.error.message;
    },
    //postProgress
    [postProgress.pending]: (state) => {
      state.postProgressStatus = "loading";
    },
    [postProgress.fulfilled]: (state) => {
      state.postProgressStatus = "success";
    },
    [postProgress.rejected]: (state, action) => {
      state.postProgressStatus = "failed";
      state.postProgressError = action.error.message;
    },
    //putProgress
    [putProgress.pending]: (state) => {
      state.putProgressStatus = "loading";
    },
    [putProgress.fulfilled]: (state, action) => {
      state.putProgressStatus = "success";
      state.getOneProgressList = action.payload;
    },
    [putProgress.rejected]: (state, action) => {
      state.putProgressStatus = "failed";
      state.putProgressError = action.error.message;
    },
    //deleteProgress
    [deleteProgress.pending]: (state) => {
      state.deleteProgressStatus = "loading";
    },
    [deleteProgress.fulfilled]: (state, action) => {
      state.deleteProgressStatus = "success";
      state.getOneProgressList = action.payload;
    },
    [deleteProgress.rejected]: (state, action) => {
      state.deleteProgressStatus = "failed";
      state.deleteProgressError = action.error.message;
    },
  },
});
export const progressActions = progressSlice.actions;
export const reducer = progressSlice.reducer;
export default progressSlice;
