import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const getSubscriptions = createAsyncThunk(
  "subscriptions/getSubscriptions",
  async (dispatch, getState,page) => {
    let data;
    if (page) {
      try {
        const response = await axios.get(`api/subscription/all?perPage=24&page=${page}`);
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
      } catch (err) {
        return {
          message: err?.message ? err?.message : "Something went wrong!",
        };
      }
    } else {
      try {
        const response = await axios.get("api/subscription/all");
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
      } catch (err) {
        return {
          message: err?.message ? err?.message : "Something went wrong!",
        };
      }
    }
  }
);

export const getOneSubscription = createAsyncThunk(
  "subscriptions/getOneSubscription",
  async (id) => {
    let data;
    try {
      const response = await axios.get(`api/subscription/get/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const postSubscription = createAsyncThunk(
  "subscriptions/postSubscription",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post("api/subscription/create", body);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getSubscriptions());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Subscription has been created successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const putSubscription = createAsyncThunk(
  "subscriptions/putSubscription ",
  async (body, thunkAPI) => {
    const { id, year, user_id, level_id } = body;
    let data;
    try {
      const response = await axios.put(`api/subscription/update/${id}`, {
        year,
        user_id,
        level_id,
      });

      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getSubscriptions());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Subscription has been updated successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  "subscriptions/deleteSubscription",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`api/subscription/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getSubscriptions());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Subscription has been deleted successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    subscriptionsList: [],
    getOneSubscriptionList: [],
    //getSubscriptions
    getSubscriptionsStatus: "idle",
    getSubscriptionsError: null,
    //getOneSubscription
    getOneSubscriptionStatus: "idle",
    getOneSubscriptionError: null,
    //postSubscription
    postSubscriptionStatus: "idle",
    postSubscriptionError: null,
    //putSubscription
    putSubscriptionStatus: "idle",
    putSubscriptionError: null,
    //deleteSubscription
    deleteSubscriptionStatus: "idle",
    deleteSubscriptionError: null,
  },
  extraReducers: {
    // getSubscriptions
    [getSubscriptions.pending]: (state) => {
      state.getSubscriptionsStatus = "loading";
    },
    [getSubscriptions.fulfilled]: (state, action) => {
      state.getSubscriptionsStatus = "success";
      state.subscriptionsList = action.payload;
    },
    [getSubscriptions.rejected]: (state, action) => {
      state.getSubscriptionsStatus = "failed";
      state.getSubscriptionsError = action.error.message;
    },
    //getOneSubscription
    [getOneSubscription.pending]: (state) => {
      state.getOneSubscriptionStatus = "loading";
    },
    [getOneSubscription.fulfilled]: (state, action) => {
      state.getOneSubscriptionStatus = "success";
      state.getOneSubscriptionList = action.payload;
    },
    [getOneSubscription.rejected]: (state, action) => {
      state.getOneSubscriptionStatus = "failed";
      state.getOneSubscriptionError = action.error.message;
    },
    //postSubscription
    [postSubscription.pending]: (state) => {
      state.postSubscriptionStatus = "loading";
    },
    [postSubscription.fulfilled]: (state) => {
      state.postSubscriptionStatus = "success";
    },
    [postSubscription.rejected]: (state, action) => {
      state.postSubscriptionStatus = "failed";
      state.postSubscriptionError = action.error.message;
    },
    //putSubscription
    [putSubscription.pending]: (state) => {
      state.putSubscriptionStatus = "loading";
    },
    [putSubscription.fulfilled]: (state, action) => {
      state.putSubscriptionStatus = "success";
      state.getOneSubscriptionList = action.payload;
    },
    [putSubscription.rejected]: (state, action) => {
      state.putSubscriptionStatus = "failed";
      state.putSubscriptionError = action.error.message;
    },

    //deleteSubscription
    [deleteSubscription.pending]: (state) => {
      state.deleteSubscriptionStatus = "loading";
    },
    [deleteSubscription.fulfilled]: (state, action) => {
      state.deleteSubscriptionStatus = "success";
      state.getOneSubscriptionList = action.payload;
    },
    [deleteSubscription.rejected]: (state, action) => {
      state.deleteSubscriptionStatus = "failed";
      state.deleteSubscriptionError = action.error.message;
    },
  },
});
export const subscriptionActions = subscriptionSlice.actions;
export const reducer = subscriptionSlice.reducer;
export default subscriptionSlice;
