import { configureStore } from "@reduxjs/toolkit";
import api from "../services/api";
import auth from "./slices/auth";
import { reducer as parentReducer } from "./slices/parentSlice";
import { reducer as levelReducer } from "./slices/levelSlice";
import { reducer as childReducer } from "./slices/childSlice";
import { reducer as subscriptionReducer } from "./slices/subscriptionSlice";
import { reducer as worldsReducer } from "./slices/worldSlice";
import { reducer as gameReducer } from "./slices/gameSlice";
import { reducer as progressReducer } from "./slices/progressSlice";
import { reducer as roleReducer } from "./slices/roleSlice";
import { reducer as adminReducer } from "./slices/adminSlice";
import { reducer as deleteReducer } from "./slices/deleteSlice";
import { reducer as stageReducer } from "./slices/stageSlice";
import { reducer as showReducer } from "./slices/showSlice";
import { reducer as questionsHomeReducer } from "./slices/questionsHome";
import reducer from "./slices/questionSlice";
import { reducer as imagesManagerReducer } from "./slices/imagesManager";
import { reducer as attributeSetReducer } from "./slices/attributeSet";
import images from "./slices/imagesSlice";

export default configureStore({
  reducer: {
    auth,
    parent: parentReducer,
    level: levelReducer,
    child: childReducer,
    subscription: subscriptionReducer,
    world: worldsReducer,
    game: gameReducer,
    progress: progressReducer,
    role: roleReducer,
    admin: adminReducer,
    delete: deleteReducer,
    stage : stageReducer,
    show: showReducer,
    questionsHome: questionsHomeReducer,
    imagesManager: imagesManagerReducer,
    attributeSet: attributeSetReducer,
    images,
    configAttributes: reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: api,
      },
      serializableCheck: false,
    }),
});