import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const getGames = createAsyncThunk("games/getGames", async (page) => {
  let data;
  if (page) {
    try {
      const response = await axios.get(`api/games/all?perPage=24&page=${page}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  } else {
    try {
      const response = await axios.get(`api/games/all`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
});
export const getOneGame = createAsyncThunk("games/getOneGame", async (id) => {
  let data;
  try {
    const response = await axios.get(`api/games/get/${id}`);
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
  } catch (err) {
    return { message: err?.message ? err?.message : "Something went wrong!" };
  }
});

export const postGame = createAsyncThunk(
  "games/postGame",
  async (body, thunkAPI) => {
    const formData = new FormData();
    formData.append("photo", body.photo);
    formData.append("name", body.name);
    formData.append("enabled_game", body.enabled_game);
    formData.append("order_game", body.order_game);
    formData.append("world_id", body.world_id);
    formData.append("stage_id", body.stage_id);

    let data;
    try {
      const response = await axios.post("api/games/create", formData);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getGames());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Game has been created successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const putGame = createAsyncThunk(
  "games/putGame ",
  async (body, thunkAPI) => {
    const { id } = body;
    const formData = new FormData();
    formData.append("photo", body.photo);
    formData.append("name", body.name);
    formData.append("enabled_game", body.enabled_game);
    formData.append("order_game", body.order_game);
    formData.append("world_id", body.world_id);
    formData.append("stage_id", body.stage_id);
    let data;
    try {
      const response = await axios.put(`api/games/update/${id}`, formData);

      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getGames());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Game has been updated successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const deleteGame = createAsyncThunk(
  "games/deleteGame",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`api/games/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getGames());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Game has been deleted successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

const gameSlice = createSlice({
  name: "game",
  initialState: {
    gamesList: [],
    getOneGameList: [],
    //getGames
    getGamesStatus: "idle",
    getGamesError: null,
    //getOneGame
    getOneGameStatus: "idle",
    getOneGameError: null,
    //postGame
    postGameStatus: "idle",
    postGameError: null,
    //putGame
    putGameStatus: "idle",
    putGameError: null,
    //deleteGame
    deleteGameStatus: "idle",
    deleteGameError: null,
  },
  extraReducers: {
    // getGames
    [getGames.pending]: (state) => {
      state.getGamesStatus = "loading";
    },
    [getGames.fulfilled]: (state, action) => {
      state.getGamesStatus = "success";
      state.gamesList = action.payload;
    },
    [getGames.rejected]: (state, action) => {
      state.getGamesStatus = "failed";
      state.getGamesError = action.error.message;
    },
    //getOneGame
    [getOneGame.pending]: (state) => {
      state.getOneGameStatus = "loading";
    },
    [getOneGame.fulfilled]: (state, action) => {
      state.getOneGameStatus = "success";
      state.getOneGameList = action.payload;
    },
    [getOneGame.rejected]: (state, action) => {
      state.getOneGameStatus = "failed";
      state.getOneGameError = action.error.message;
    },
    //postGame
    [postGame.pending]: (state) => {
      state.postGameStatus = "loading";
    },
    [postGame.fulfilled]: (state) => {
      state.postGameStatus = "success";
    },
    [postGame.rejected]: (state, action) => {
      state.postGameStatus = "failed";
      state.postGameError = action.error.message;
    },
    //putGame
    [putGame.pending]: (state) => {
      state.putGameStatus = "loading";
    },
    [putGame.fulfilled]: (state, action) => {
      state.putGameStatus = "success";
      state.getOneGameList = action.payload;
    },
    [putGame.rejected]: (state, action) => {
      state.putGameStatus = "failed";
      state.putGameError = action.error.message;
    },
    //deleteGame
    [deleteGame.pending]: (state) => {
      state.deleteGameStatus = "loading";
    },
    [deleteGame.fulfilled]: (state, action) => {
      state.deleteGameStatus = "success";
      state.getOneGameList = action.payload;
    },
    [deleteGame.rejected]: (state, action) => {
      state.deleteGameStatus = "failed";
      state.deleteGameError = action.error.message;
    },
  },
});
export const gameActions = gameSlice.actions;
export const reducer = gameSlice.reducer;
export default gameSlice;
