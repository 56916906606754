import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const getChildren = createAsyncThunk(
  "children/getChildren",
  async (page) => {
    let data;
    if (page) {
      try {
        const response = await axios.get(
          `api/children/all?perPage=24&page=${page}`
        );
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
      } catch (err) {
        return {
          message: err?.message ? err?.message : "Something went wrong!",
        };
      }
    } else {
      try {
        const response = await axios.get(`api/children/all`);
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
      } catch (err) {
        return {
          message: err?.message ? err?.message : "Something went wrong!",
        };
      }
    }
  }
);

export const getOneChild = createAsyncThunk(
  "children/getOneChild",
  async (id) => {
    let data;
    try {
      const response = await axios.get(`api/children/get/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const postChild = createAsyncThunk(
  "children/postChild",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post("api/children/create", body);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getChildren());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Child has been created successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const putChild = createAsyncThunk(
  "children/putChild ",
  async (body, thunkAPI) => {
    const { id, name, last_name, parents_id, level_id } = body;
    let data;
    try {
      const response = await axios.put(`api/children/update/${id}`, {
        name,
        last_name,
        parents_id,
        level_id,
      });

      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getChildren());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Child has been updated successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const deleteChild = createAsyncThunk(
  "children/deleteChild",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`api/children/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getChildren());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Child has been deleted successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

const childSlice = createSlice({
  name: "child",
  initialState: {
    childrenList: [],
    getOneChildList: [],
    status: null,
    //getChildren
    getChildrenStatus: "idle",
    getChildrenError: null,
    //getOneChild
    getOneChildStatus: "idle",
    getOneChildError: null,
    //postChild
    postChildStatus: "idle",
    postChildError: null,
    //putChild
    putChildStatus: "idle",
    putChildError: null,
    //deleteChild
    deleteChildStatus: "idle",
    deleteChildError: null,
  },
  extraReducers: {
    // getChildren
    [getChildren.pending]: (state) => {
      state.getChildrenStatus = "loading";
    },
    [getChildren.fulfilled]: (state, action) => {
      state.getChildrenStatus = "success";
      state.childrenList = action.payload;
    },
    [getChildren.rejected]: (state, action) => {
      state.getChildrenStatus = "failed";
      state.getChildrenError = action.error.message;
    },
    //getOneChild
    [getOneChild.pending]: (state) => {
      state.getOneChildStatus = "loading";
    },
    [getOneChild.fulfilled]: (state, action) => {
      state.getOneChildStatus = "success";
      state.getOneChildList = action.payload;
    },
    [getOneChild.rejected]: (state, action) => {
      state.getOneChildStatus = "failed";
      state.getOneChildError = action.error.message;
    },
    //postChild
    [postChild.pending]: (state) => {
      state.postChildStatus = "loading";
    },
    [postChild.fulfilled]: (state) => {
      state.postChildStatus = "success";
    },
    [postChild.rejected]: (state, action) => {
      state.postChildStatus = "failed";
      state.postChildError = action.error.message;
    },
    //putChild
    [putChild.pending]: (state) => {
      state.putChildStatus = "loading";
    },
    [putChild.fulfilled]: (state, action) => {
      state.putChildStatus = "success";
      state.getOneChildList = action.payload;
    },
    [putChild.rejected]: (state, action) => {
      state.putChildStatus = "failed";
      state.putChildError = action.error.message;
    },

    //deleteChild
    [deleteChild.pending]: (state) => {
      state.deleteChildStatus = "loading";
    },
    [deleteChild.fulfilled]: (state, action) => {
      state.deleteChildStatus = "success";
      state.getOneChildList = action.payload;
    },
    [deleteChild.rejected]: (state, action) => {
      state.deleteChildStatus = "failed";
      state.deleteChildError = action.error.message;
    },
  },
});
export const childActions = childSlice.actions;
export const reducer = childSlice.reducer;
export default childSlice;
