import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../utils/axios'
export const deleteItem = createAsyncThunk(
    "deleteItem/Item",
    async (body, thunkAPI) => {
        const { handleDeleteUrl, itemId, handleDeleteGetAllItems } = body
        let data;
        try {
            const response = await axios.delete(`api/${handleDeleteUrl}/delete/${itemId}`);
            data = await response.data;
            if ((response.status = 200)) {
                thunkAPI.dispatch(handleDeleteGetAllItems);
                return { message: response?.payload?.message ? response?.payload?.message : 'Item has been deleted successfully' };
            }
        } catch (err) {
            return { message: err?.message ? err?.message : "Something went wrong!" };
        }
    }
);
const deleteSlice = createSlice({
    name: "delete",
    initialState: {
        getOneItemList: [],
        //deleteItem
        deleteItemStatus: "idle",
        deleteItemError: null,
    },
    extraReducers: {
        //deleteItem
        [deleteItem.pending]: (state,) => {
            state.deleteItemStatus = "loading";
        },
        [deleteItem.fulfilled]: (state, action) => {
            state.deleteItemStatus = "success";
            state.getOneItemList = action.payload;
        },
        [deleteItem.rejected]: (state, action) => {
            state.deleteItemStatus = "failed";
            state.deleteItemError = action.error.message;
        },

    },
});
export const deleteActions = deleteSlice.actions;
export const reducer = deleteSlice.reducer;
export default deleteSlice;
