import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const getWorlds = createAsyncThunk("worlds/getWorlds", async (page) => {
  let data;
  if (page) {
    try {
      const response = await axios.get(
        `api/worlds/all?perPage=24&page=${page}`
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  } else {
    try {
      const response = await axios.get(`api/worlds/all`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return {
        message: err?.message ? err?.message : "Something went wrong!",
      };
    }
  }
});

export const getOneWorld = createAsyncThunk(
  "worlds/getOneWorld",
  async (id) => {
    let data;
    try {
      const response = await axios.get(`api/worlds/get/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const worldsByLevelId = createAsyncThunk(
  "worlds/worldsByLevelId",
  async (id) => {
    let data;
    try {
      const response = await axios.get(`api/worlds/getByLevel/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const postWorld = createAsyncThunk(
  "worlds/postWorld",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post("api/worlds/create", body);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getWorlds());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "World has been created successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const putWorld = createAsyncThunk(
  "worlds/putWorld ",
  async (body, thunkAPI) => {
    const { id, name, order_world, enabled_world, level_id } = body;
    let data;
    try {
      const response = await axios.put(`api/worlds/update/${id}`, {
        name,
        order_world,
        enabled_world,
        level_id,
      });

      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getWorlds());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "World has been updated successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const deleteWorld = createAsyncThunk(
  "worlds/deleteWorld",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`api/worlds/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getWorlds());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "World has been deleted successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

const worldSlice = createSlice({
  name: "world",
  initialState: {
    worldsList: [],
    getOneWorldList: [],
    //getWorlds
    getWorldsStatus: "idle",
    getWorldsError: null,
    //getOneWorld
    getOneWorldStatus: "idle",
    getOneWorldError: null,
    //postWorld
    postWorldStatus: "idle",
    postWorldError: null,
    //putWorld
    putWorldStatus: "idle",
    putWorldError: null,
    //deleteWorld
    deleteWorldStatus: "idle",
    deleteWorldError: null,
  },
  extraReducers: {
    // getWorlds
    [getWorlds.pending]: (state) => {
      state.getWorldsStatus = "loading";
    },
    [getWorlds.fulfilled]: (state, action) => {
      state.getWorldsStatus = "success";
      state.worldsList = action.payload;
    },
    [getWorlds.rejected]: (state, action) => {
      state.getWorldsStatus = "failed";
      state.getWorldsError = action.error.message;
    },
    //getOneWorld
    [getOneWorld.pending]: (state) => {
      state.getOneWorldStatus = "loading";
    },
    [getOneWorld.fulfilled]: (state, action) => {
      state.getOneWorldStatus = "success";
      state.getOneWorldList = action.payload;
    },
    [getOneWorld.rejected]: (state, action) => {
      state.getOneWorldStatus = "failed";
      state.getOneWorldError = action.error.message;
    },
    //get worlds by level ID
    [getOneWorld.pending]: (state) => {
      state.worldsByLevelId = "loading";
    },
    [getOneWorld.fulfilled]: (state, action) => {
      state.worldsByLevelId = "success";
      state.getOneWorldList = action.payload;
    },
    [getOneWorld.rejected]: (state, action) => {
      state.worldsByLevelId = "failed";
      state.getOneWorldError = action.error.message;
    },
    //postWorld
    [postWorld.pending]: (state) => {
      state.postWorldStatus = "loading";
    },
    [postWorld.fulfilled]: (state) => {
      state.postWorldStatus = "success";
    },
    [postWorld.rejected]: (state, action) => {
      state.postWorldStatus = "failed";
      state.postWorldError = action.error.message;
    },
    //putWorld
    [putWorld.pending]: (state) => {
      state.putWorldStatus = "loading";
    },
    [putWorld.fulfilled]: (state, action) => {
      state.putWorldStatus = "success";
      state.getOneWorldList = action.payload;
    },
    [putWorld.rejected]: (state, action) => {
      state.putWorldStatus = "failed";
      state.putWorldError = action.error.message;
    },

    //deleteWorld
    [deleteWorld.pending]: (state) => {
      state.deleteWorldStatus = "loading";
    },
    [deleteWorld.fulfilled]: (state, action) => {
      state.deleteWorldStatus = "success";
      state.getOneWorldList = action.payload;
    },
    [deleteWorld.rejected]: (state, action) => {
      state.deleteWorldStatus = "failed";
      state.deleteWorldError = action.error.message;
    },
  },
});
export const worldActions = worldSlice.actions;
export const reducer = worldSlice.reducer;
export default worldSlice;
