import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MinimalLayout from "./layouts/Minimal";
import MainLayout from "./layouts/Main";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import { Helmet } from "react-helmet";

export const RenderRoutes = ({ routes }) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    <Helmet>
                      <title>{route.title}</title>
                    </Helmet>
                    {route.routes ? (
                      <RenderRoutes routes={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

const routes = [
  {
    exact: true,
    layout: MinimalLayout,
    path: "/404",
    component: lazy(() => import("./views/NotFound")),
  },
  {
    exact: true,
    layout: MinimalLayout,
    //guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("./views/LoginView")),
  },
  {
    path: "/",
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/",
        component: lazy(() => import("./views/Dashboard")),
      },
      {
        exact: true,
        path: "/account",
        component: lazy(() => import("./views/Account")),
        title: "TA|Account",
      },
      //parents
      {
        exact: true,
        path: "/users/parents",
        component: lazy(() => import("./views/Users/Parent/ParentList")),
        title: "TA|Parents",
      },
      {
        exact: true,
        path: "/users/parents/create",
        component: lazy(() => import("./views/Users/Parent/ParentCreate")),
        title: "TA|Parents-create",
      },
      {
        exact: true,
        path: "/users/parents/edit/:id",
        component: lazy(() => import("./views/Users/Parent/ParentEdit")),
        title: "TA|Parents-edit",
      },
      {
        exact: true,
        path: "/users/parents/view/:id",
        component: lazy(() => import("./views/Users/Parent/ParentShow")),
        title: "TA|Parents-view",
      },
      {
        exact: true,
        path: "/users/parents/edit/children/edit/:id",
        component: lazy(() =>
          import("./views/Users/Parent/ParentView/ChildEdit")
        ),
        title: "TA|Children-edit",
      },
      {
        exact: true,
        path: "/users/parents/edit/children/view/:id",
        component: lazy(() =>
          import("./views/Users/Parent/ParentView/ChildShow")
        ),
        title: "TA|Children-view",
      },
      //levels
      {
        exact: true,
        path: "/config/levels",
        component: lazy(() => import("./views/Level/LevelList")),
        title: "TA|Levels",
      },
      {
        exact: true,
        path: "/config/levels/create",
        component: lazy(() => import("./views/Level/LevelCreate")),
        title: "TA|Levels-create",
      },
      {
        exact: true,
        path: "/config/levels/edit/:id",
        component: lazy(() => import("./views/Level/LevelEdit")),
        title: "TA|Levels-edit",
      },
      {
        exact: true,
        path: "/config/levels/view/:id",
        component: lazy(() => import("./views/Level/LevelShow")),
        title: "TA|Levels-view",
      },
      //Progress
      {
        exact: true,
        path: "/config/progress",
        component: lazy(() => import("./views/Progress/ProgressList")),
        title: "TA|progress",
      },

      {
        exact: true,
        path: "/config/progress/edit/:id",
        component: lazy(() => import("./views/Progress/ProgressEdit")),
        title: "TA|progress-edit",
      },
      {
        exact: true,
        path: "/config/progress/view/:id",
        component: lazy(() => import("./views/Progress/ProgressShow")),
        title: "TA|progress-view",
      },
      //Subscription
      {
        exact: true,
        path: "/config/subscriptions",
        component: lazy(() => import("./views/Subscription/SubscriptionList")),
        title: "TA|Subscriptions",
      },

      {
        exact: true,
        path: "/config/subscriptions/edit/:id",
        component: lazy(() => import("./views/Subscription/SubscriptionEdit")),
        title: "TA|Subscriptions-edit",
      },
      {
        exact: true,
        path: "/config/subscriptions/view/:id",
        component: lazy(() => import("./views/Subscription/SubscriptionShow")),
        title: "TA|Subscriptions-view",
      },
      //Game
      {
        exact: true,
        path: "/worlds-and-games/games",
        component: lazy(() => import("./views/Game/GameList")),
        title: "TA|Games",
      },
      {
        exact: true,
        path: "/worlds-and-games/games/create",
        component: lazy(() => import("./views/Game/GameCreate")),
        title: "TA|Games-create",
      },
      {
        exact: true,
        path: "/worlds-and-games/games/edit/:id",
        component: lazy(() => import("./views/Game/GameEdit")),
        title: "TA|Games-edit",
      },
      {
        exact: true,
        path: "/worlds-and-games/games/view/:id",
        component: lazy(() => import("./views/Game/GameShow")),
        title: "TA|Games-view",
      },
      //world
      {
        exact: true,
        path: "/worlds-and-games/worlds",
        component: lazy(() => import("./views/World/WorldList")),
        title: "TA|Worlds",
      },
      {
        exact: true,
        path: "/worlds-and-games/worlds/create",
        component: lazy(() => import("./views/World/WorldCreate")),
        title: "TA|Worlds-create",
      },
      {
        exact: true,
        path: "/worlds-and-games/worlds/edit/:id",
        component: lazy(() => import("./views/World/WorldEdit")),
        title: "TA|Worlds-edit",
      },
      {
        exact: true,
        path: "/worlds-and-games/worlds/view/:id",
        component: lazy(() => import("./views/World/WorldShow")),
        title: "TA|Worlds-view",
      },
      //Child
      {
        exact: true,
        path: "/users/children",
        component: lazy(() => import("./views/Users/Child/ChildList")),
        title: "TA|Children",
      },
      {
        exact: true,
        path: "/users/children/create",
        component: lazy(() => import("./views/Users/Child/ChildCreate")),
        title: "TA|Children-create",
      },
      {
        exact: true,
        path: "/users/children/edit/:id",
        component: lazy(() => import("./views/Users/Child/ChildEdit")),
        title: "TA|Children-edit",
      },
      {
        exact: true,
        path: "/users/children/view/:id",
        component: lazy(() => import("./views/Users/Child/ChildShow")),
        title: "TA|Children-view",
      },
      {
        exact: true,
        path: "/users/children/edit/subscriptions/edit/:id",
        component: lazy(() =>
          import("./views/Users/Child/ChildView/SubscriptionEdit")
        ),
        title: "TA|Subscription-edit",
      },
      {
        exact: true,
        path: "/users/children/edit/subscriptions/view/:id",
        component: lazy(() =>
          import("./views/Users/Child/ChildView/SubscriptionShow")
        ),
        title: "TA|Subscription-view",
      },
      {
        exact: true,
        path: "/users/children/edit/progress/edit/:id",
        component: lazy(() =>
          import("./views/Users/Child/ChildView/ProgressEdit")
        ),
        title: "TA|Progress-edit",
      },
      {
        exact: true,
        path: "/users/children/edit/progress/view/:id",
        component: lazy(() =>
          import("./views/Users/Child/ChildView/ProgressShow")
        ),
        title: "TA|Progress-view",
      },
      //questions home
      {
        path: "/questions-home",
        label: "Questions Home",
        component: lazy(() => import("./views/Questions/QuestionsHome")),
      },
      //attribute config
      {
        path: "/config-attributes",
        label: "Attributes config",
        component: lazy(() => import("./views/Attributes/ConfigAttributes")),
      },
      //question page
      {
        path: "/question-generator",
        label: "Question page",
        component: lazy(() => import("./views/Questions/Questions")),
      },
      //images manager
      {
        path: "/images-manager",
        label: "Images manager",
        component: lazy(() => import("./views/Images/ImagesManager")),
      },
      //questions by style
      {
        path: "/questions-style",
        label: "Questions by style",
        component: lazy(() => import("./views/QuestionsStyle/QuestionsStyle")),
      },
      //dashboard
      {
        exact: true,
        path: "/dashboard",
        component: () => <Redirect to="/" />,
        title: "TA|Dashboard",
      },
    ],
  },
  {
    path: "*",
    layout: MinimalLayout,
    routes: [
      // {
      //   exact: true,
      //   path: '/',
      //   component: HomeView
      // },
      {
        component: () => <Redirect to="/404" />,
        title: "TA|Not found",
      },
    ],
  },
];

export default routes;
