import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { toast } from "react-toastify";

export const getParents = createAsyncThunk(
  "parents/getParents",
  async (page) => {
    let data;
    if (page) {
      try {
        const response = await axios.get(
          `api/parent/all?perPage=24&page=${page}`
        );
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
      } catch (err) {
        return {
          message: err?.message ? err?.message : "Something went wrong!",
        };
      }
    } else {
      try {
        const response = await axios.get(`api/parent/all`);
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
      } catch (err) {
        return {
          message: err?.message ? err?.message : "Something went wrong!",
        };
      }
    }
  }
);

export const getOneParent = createAsyncThunk(
  "parents/getOneParent",
  async (id) => {
    let data;
    try {
      const response = await axios.get(`api/parent/get/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const postParent = createAsyncThunk(
  "parents/postParent",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post("api/parent/create", body);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getParents());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Parent has been created successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const putParent = createAsyncThunk(
  "parents/putParent",
  async (body, thunkAPI) => {
    const {
      id,
      name,
      last_name,
      email,
      address,
      phone_number,
      password,
    } = body;
    let data;
    try {
      const response = await axios.put(`api/parent/update/${id}`, {
        name,
        last_name,
        email,
        address,
        phone_number,
        password,
      });
      data = await response.data;

      if (response.status == 200) {
        thunkAPI.dispatch(getParents());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Parent has been updated successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const deleteParent = createAsyncThunk(
  "parents/deleteParent",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`api/parent/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getParents());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Parent has been deleted successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

const parentSlice = createSlice({
  name: "parent",
  initialState: {
    parentsList: [],
    getOneParentList: [],
    status: null,
    //getParents
    getParentsStatus: "idle",
    getParentsError: null,
    //getOneParent
    getOneParentStatus: "idle",
    getOneParentError: null,
    //postParent
    postParentStatus: "idle",
    postParentError: null,
    //putParent
    putParentStatus: "idle",
    putParentError: null,
    //deleteParent
    deleteParentStatus: "idle",
    deleteParentError: null,
  },
  extraReducers: {
    //getParents
    [getParents.pending]: (state) => {
      state.getParentsStatus = "loading";
    },
    [getParents.fulfilled]: (state, action) => {
      state.getParentsStatus = "success";
      state.parentsList = action.payload;
    },
    [getParents.rejected]: (state, action) => {
      state.getParentsStatus = "failed";
      state.getParentsError = action.error.message;
    },
    //getOneParent
    [getOneParent.pending]: (state) => {
      state.getOneParentStatus = "loading";
    },
    [getOneParent.fulfilled]: (state, action) => {
      state.getOneParentStatus = "success";
      state.getOneParentList = action.payload;
    },
    [getOneParent.rejected]: (state, action) => {
      state.getOneParentStatus = "failed";
      state.getOneParentError = action.error.message;
    },
    //postParent
    [postParent.pending]: (state) => {
      state.postParentStatus = "loading";
    },
    [postParent.fulfilled]: (state) => {
      state.postParentStatus = "success";
    },
    [postParent.rejected]: (state, action) => {
      state.postParentStatus = "failed";
      state.postParentError = action.error.message;
    },

    //putParent
    [putParent.pending]: (state) => {
      state.putParentStatus = "loading";
    },
    [putParent.fulfilled]: (state, action) => {
      state.putParentStatus = "success";
      state.getOneParentList = action.payload;
    },
    [putParent.rejected]: (state, action) => {
      state.putParentStatus = "failed";
      state.putParentError = action.error.message;
    },

    //deleteParent
    [deleteParent.pending]: (state) => {
      state.deleteParentStatus = "loading";
    },
    [deleteParent.fulfilled]: (state) => {
      state.deleteParentStatus = "success";
    },
    [deleteParent.rejected]: (state, action) => {
      state.deleteParentStatus = "failed";
      state.deleteParentError = action.error.message;
    },
  },
});
export const parentActions = parentSlice.actions;
export const reducer = parentSlice.reducer;
export default parentSlice;
