import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const getLevels = createAsyncThunk("levels/getLevels", async () => {
  let data;
  try {
    const response = await axios.get("api/levels/all");
    data = response.data;
    if ((response.status = 200)) {
      return data;
    }
  } catch (err) {
    return { message: err?.message ? err?.message : "Something went wrong!" };
  }
});

export const getOneLevel = createAsyncThunk(
  "levels/getOneLevel",
  async (id) => {
    let data;
    try {
      const response = await axios.get(`api/levels/get/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const postLevel = createAsyncThunk(
  "levels/postLevel",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post("api/levels/create", body);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getLevels());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Level has been created successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const putLevel = createAsyncThunk(
  "levels/putLevel ",
  async (body, thunkAPI) => {
    const { id, name, order_level } = body;
    let data;
    try {
      const response = await axios.put(`api/levels/update/${id}`, {
        name,
        order_level,
      });

      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getLevels());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Level has been updated successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const deleteLevel = createAsyncThunk(
  "levels/deleteLevel",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`api/levels/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getLevels());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Level has been deleted successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

const levelSlice = createSlice({
  name: "level",
  initialState: {
    levelsList: [],
    getOneLevelList: [],
    //getLevels
    getLevelsStatus: "idle",
    getLevelsError: null,
    //getOneLevel
    getOneLevelStatus: "idle",
    getOneLevelError: null,
    //postLevel
    postLevelStatus: "idle",
    postLevelError: null,
    //putLevel
    putLevelStatus: "idle",
    putLevelError: null,
    //deleteLevel
    deleteLevelStatus: "idle",
    deleteLevelError: null,
  },
  extraReducers: {
    // getLevels
    [getLevels.pending]: (state) => {
      state.getLevelsStatus = "loading";
    },
    [getLevels.fulfilled]: (state, action) => {
      state.getLevelsStatus = "success";
      state.levelsList = action.payload;
    },
    [getLevels.rejected]: (state, action) => {
      state.getLevelsStatus = "failed";
      state.getLevelsError = action.error.message;
    },
    //getOneLevel
    [getOneLevel.pending]: (state) => {
      state.getOneLevelStatus = "loading";
    },
    [getOneLevel.fulfilled]: (state, action) => {
      state.getOneLevelStatus = "success";
      state.getOneLevelList = action.payload;
    },
    [getOneLevel.rejected]: (state, action) => {
      state.getOneLevelStatus = "failed";
      state.getOneLevelError = action.error.message;
    },
    //postLevel
    [postLevel.pending]: (state) => {
      state.postLevelStatus = "loading";
    },
    [postLevel.fulfilled]: (state) => {
      state.postLevelStatus = "success";
    },
    [postLevel.rejected]: (state, action) => {
      state.postLevelStatus = "failed";
      state.postLevelError = action.error.message;
    },
    //putLevel
    [putLevel.pending]: (state) => {
      state.putLevelStatus = "loading";
    },
    [putLevel.fulfilled]: (state, action) => {
      state.putLevelStatus = "success";
      state.getOneLevelList = action.payload;
    },
    [putLevel.rejected]: (state, action) => {
      state.putLevelStatus = "failed";
      state.putLevelError = action.error.message;
    },

    //deleteLevel
    [deleteLevel.pending]: (state) => {
      state.deleteLevelStatus = "loading";
    },
    [deleteLevel.fulfilled]: (state, action) => {
      state.deleteLevelStatus = "success";
      state.getOneLevelList = action.payload;
    },
    [deleteLevel.rejected]: (state, action) => {
      state.deleteLevelStatus = "failed";
      state.deleteLevelError = action.error.message;
    },
  },
});
export const levelActions = levelSlice.actions;
export const reducer = levelSlice.reducer;
export default levelSlice;
