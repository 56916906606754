import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    tabNumber: 1,
    childTabNumber: 1,
};

const showSlice = createSlice({
    name: 'show',
    initialState,

    reducers: {
        showHandler(state, action) {
            const tabNumber = action.payload;
            state.tabNumber = tabNumber
        },
        childShowTabHandler(state, action) {
            const childTabNumber = action.payload;
            state.childTabNumber = childTabNumber
        }
    },
});

export const showActions = showSlice.actions;
export const reducer = showSlice.reducer;
export default showSlice;
