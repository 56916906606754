import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useReducer,
} from "react";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SplashScreen from "../components/SplashScreen";
import axios from "../utils/axios";
import headers from "../helpers/headers";
import SnackBar from "components/SnackBar";
import slice from "redux/slices/modals";
const apiUrl = process.env.REACT_APP_API_URL;

const initialAuthState = {
  //isAuthenticated: false,
  isAuthenticated: false,
  isInitialised: false,
};
toast.configure();

const isValidToken = (token) => {
  if (!token) {
    // for testing
    return true;
    //return false;
  }

  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (token) => {
  if (token) {
    localStorage.setItem("token", token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (username, password) => {
    const response = await axios.post(`/api/signin`, {
      username,
      password,
    });

    if (!response) {
      toast.error(`The username or password is incorrect`, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const accessToken = response.data.payload.tokens.accessToken;
      setSession(accessToken);
      dispatch({
        type: "LOGIN",
        payload: {
          accessToken,
        },
      });
      toast.success(`Welcome Back`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return (window.location = "/");
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  const register = async (username, name, password) => {
    const response = await axios.post("/api/account/register", {
      username,
      name,
      password,
    });
    const { token, user } = response.data;

    window.localStorage.setItem("token", token);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const token = window.localStorage.getItem("token");
        if (token && isValidToken(token)) {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
export const useStore = () => useContext(AuthContext);
