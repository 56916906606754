import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const getStages = createAsyncThunk("stages/getStages", async () => {
  let data;
  try {
    const response = await axios.get("api/stages/all");
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
  } catch (err) {
    return { message: err?.message ? err?.message : "Something went wrong!" };
  }
});

export const getOneStage = createAsyncThunk(
  "stages/getOneStage",
  async (id) => {
    let data;
    try {
      const response = await axios.get(`stageslist/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const postStage = createAsyncThunk(
  "stages/postStage",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post("stageslist", body);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getStages());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Stage has been created successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const putStage = createAsyncThunk(
  "stages/putStage ",
  async (body, thunkAPI) => {
    const { id, name, order, worldsId, subjectsId } = body;
    let data;
    try {
      const response = await axios.put(`stageslist/${id}`, {
        name,
        order,
        worldsId,
        subjectsId,
      });

      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getStages());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Stage has been created successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

export const deleteStage = createAsyncThunk(
  "stages/deleteStage",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`stageslist/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getStages());
        return {
          data: data,
          message: response?.payload?.message
            ? response?.payload?.message
            : "Stage has been deleted successfully",
        };
      }
    } catch (err) {
      return { message: err?.message ? err?.message : "Something went wrong!" };
    }
  }
);

const stageSlice = createSlice({
  name: "stage",
  initialState: {
    stagesList: [],
    getOneStageList: [],
    //getStages
    getStagesStatus: "idle",
    getStagesError: null,
    //getOneStage
    getOneStageStatus: "idle",
    getOneStageError: null,
    //postStage
    postStageStatus: "idle",
    postStageError: null,
    //putStage
    putStageStatus: "idle",
    putStageError: null,
    //deleteStage
    deleteStageStatus: "idle",
    deleteStageError: null,
  },
  extraReducers: {
    // getStages
    [getStages.pending]: (state) => {
      state.getStagesStatus = "loading";
    },
    [getStages.fulfilled]: (state, action) => {
      state.getStagesStatus = "success";
      state.stagesList = action.payload;
    },
    [getStages.rejected]: (state, action) => {
      state.getStagesStatus = "failed";
      state.getStagesError = action.error.message;
    },
    //getOneStage
    [getOneStage.pending]: (state) => {
      state.getOneStageStatus = "loading";
    },
    [getOneStage.fulfilled]: (state, action) => {
      state.getOneStageStatus = "success";
      state.getOneStageList = action.payload;
    },
    [getOneStage.rejected]: (state, action) => {
      state.getOneStageStatus = "failed";
      state.getOneStageError = action.error.message;
    },
    //postStage
    [postStage.pending]: (state) => {
      state.postStageStatus = "loading";
    },
    [postStage.fulfilled]: (state) => {
      state.postStageStatus = "success";
    },
    [postStage.rejected]: (state, action) => {
      state.postStageStatus = "failed";
      state.postStageError = action.error.message;
    },
    //putStage
    [putStage.pending]: (state) => {
      state.putStageStatus = "loading";
    },
    [putStage.fulfilled]: (state, action) => {
      state.putStageStatus = "success";
      state.getOneStageList = action.payload;
    },
    [putStage.rejected]: (state, action) => {
      state.putStageStatus = "failed";
      state.putStageError = action.error.message;
    },

    //deleteStage
    [deleteStage.pending]: (state) => {
      state.deleteStageStatus = "loading";
    },
    [deleteStage.fulfilled]: (state, action) => {
      state.deleteStageStatus = "success";
      state.getOneStageList = action.payload;
    },
    [deleteStage.rejected]: (state, action) => {
      state.deleteStageStatus = "failed";
      state.deleteStageError = action.error.message;
    },
  },
});
export const stageActions = stageSlice.actions;
export const reducer = stageSlice.reducer;
export default stageSlice;
