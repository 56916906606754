import axios from "axios";
import headers from "helpers/headers";
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers,
});
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error?.response?.status === 401) {
    //   localStorage.removeItem('token');
    //   window.location.href = '/';
    // } else {
console.log(error?.response?.data?.error)
    Promise.reject( "Something went wrong");
    // }
  }
);
export default axiosInstance;
