import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../utils/axios'


export const getRoles = createAsyncThunk(
    "roles/getRoles",
    async () => {
        let data;
        try {
            const response = await axios.get("roleslist");
            data = await response.data;
            if ((response.status = 200)) {
                return data;
            }
        } catch (err) {
            return { message: err?.message ? err?.message : "Something went wrong!" };
        }
    }
);


export const getOneRole = createAsyncThunk(
    "roles/getOneRole",
    async (id) => {
        let data;
        try {
            const response = await axios.get(`roleslist/${id}`);
            data = await response.data;
            if ((response.status = 200)) {
                return data;
            }
        } catch (err) {
            return { message: err?.message ? err?.message : "Something went wrong!" };
        }
    }
);

const roleSlice = createSlice({
    name: "role",
    initialState: {
        rolesList: [],
        getOneRoleList: [],
        //getRoles
        getRolesStatus: "idle",
        getRolesError: null,
        //getOneRole
        getOneRoleStatus: "idle",
        getOneRoleError: null,
    },
    extraReducers: {
        // getRoles
        [getRoles.pending]: (state,) => {
            state.getRolesStatus = "loading";
        },
        [getRoles.fulfilled]: (state, action) => {
            state.getRolesStatus = "success";
            state.rolesList = action.payload;
        },
        [getRoles.rejected]: (state, action) => {
            state.getRolesStatus = "failed";
            state.getRolesError = action.error.message;
        },
        //getOneRole
        [getOneRole.pending]: (state,) => {
            state.getOneRoleStatus = "loading";
        },
        [getOneRole.fulfilled]: (state, action) => {
            state.getOneRoleStatus = "success";
            state.getOneRoleList = action.payload;
        },
        [getOneRole.rejected]: (state, action) => {
            state.getOneRoleStatus = "failed";
            state.getOneRoleError = action.error.message;
        },
    },
});
export const roleActions = roleSlice.actions;
export const reducer = roleSlice.reducer;
export default roleSlice;